<template>
    <div>

    </div>
</template>

<script>
export default {
    data () {
      return {

      }
    },

    mounted(){
      
    },

    methods: {

      loading() {
        return this.$store.getters.loading
      },

      signOut() {
        this.$store.dispatch('signOut')
      },
      
    },

    computed: {
      user() {
          return this.$store.getters.user
      },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/main';

</style>

