export default {
    data() {

        var vueurl = "https://teman.plus.com.my/plus/";

        if(location.hostname == 'localhost' && location.protocol == 'http:')        vueurl = "https://teman.plus.com.my/plus/";
        else if(location.hostname != 'localhost' && location.protocol == 'http:')   vueurl = "http://teman.plus.com.my/plus/";
        else vueurl = "https://teman.plus.com.my/plus/";

        
        return {

            // vueUrl: "https://donutgebu.shaz.my/plus/"
            vueUrl: vueurl

        }
    },    
    mounted(){
        
    },
    methods: {

        goToPage(url){
            this.$router.push({ path: url }).catch(()=>{});
        },

        backToLandingPage()
        {
            window.location.href ="/LandingPage";
        }
        
    },
    computed: {

        // levelAdmin() {
        //     return this.$store.getters.userRole === 'admin' ? true : false
        // },

        // levelUser() {
        //     return this.$store.getters.userRole === 'user' ? true : false
        // },

    },
}