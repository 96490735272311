import Vue from "vue";
import VueRouter from "vue-router";
import AuthGuard from './auth-guard'


Vue.use(VueRouter)


// const router = createRouter({ routes: VueRouter.routes })

// router.beforeEach((to, from) => {
//   // ...
//   // explicitly return false to cancel the navigation
//   return false
// })

// this.$router.beforeEach((to, from, next)=>{
    
//         alert(123);


//   })


// const routt = new VueRouter();


// routt.beforeEach((to, from) => {
//     // ...
//     // explicitly return false to cancel the navigation

//     alert(1231);

//     return false;
//   })



export default new VueRouter({
    mode: "history",
    routes: [
        
        // == Home ==
        { 
            path: "/",
            name: "Main Login",
            component: () => import('@/views/MainLogin'),
            meta: { showFooter: false}
            
        },
        { 
            path: "/ExternalLogin",
            name: "External Login",
            component: () => import('@/views/ExternalLogin.vue'),
            meta: { showFooter: false},
            
        },
        {
            path: "/Loading",
            name: "Loading",
            component: () => import('@/views/Loading.vue'),
            meta: { showFooter: false },

        },
        {
            path: "/Logout",
            name: "Loading Out",
            component: () => import('@/views/LoadingOut.vue'),
            meta: { showFooter: false },

        },
        { 
            path: "/OlddLandingPage",
            name: "Old Landing Page",
            component: () => import('@/views/OldLandingPage.vue'),
            meta: { showFooter: false},
            
        },
        { 
            path: "/LandingPage",
            name: "Landing Page",
            component: () => import('@/views/LandingPage.vue'),
            meta: { showFooter: false},            
            beforeEnter: AuthGuard
            
        },
        { 
            path: "/ForgotPassword",
            name: "Forgot Password",
            component: () => import('@/views/ForgotPassword.vue'),
            meta: { showFooter: false},
            
        },
        { 
            path: "/UserManagement",
            name: "User Management",
            component: () => import('@/views/Admin/UserManagement.vue'),
            meta: { showFooter: false},
            beforeEnter: AuthGuard
            
        },
        // { 
        //     path: "/UserList",
        //     name: "User List",
        //     component: () => import('@/components/Admin/UserList.vue'),
        //     meta: { showFooter: false},
            
        // },

    ]
});
