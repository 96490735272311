<template>
  <v-app>
    <!-- app header -->
    <app-header></app-header>

    <!-- content -->
    <v-main>
      <router-view :key="$route.path"></router-view>
    </v-main>

    <!-- app footer -->
    <app-footer></app-footer>

    <v-dialog
    v-model="dialogGeneralPopup"
    persistent
    max-width="400"
    >
    
      <v-card>
        <v-card-title :class="status == 'success'? 'text-h5 success--text' : 'text-h5 error--text'">
          <v-icon :color="status == 'success' ? 'success' : 'error'" class="mr-2">{{ icon }}</v-icon>
          {{ title }}
        </v-card-title>

        <v-card-text class="black--text">
          {{ text }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          color="primary"
          text
          @click="closeDialog"
          >
          OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { bus } from '@/main';
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
export default {
  name: "App",
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
  },

  data: () => ({
    dialogGeneralPopup: false,
    status:"",
    icon:"",
    title:"",
    text:"",

    notaTambahan: "",

  }),

  mounted(){
    this.load();
  },

  methods: {
    load(){
      // console.log(body);
    },

    closeDialog()
    {
      if(this.notaTambahan == 'jumptoExternalLogin')
      {
        this.dialogGeneralPopup = false;
        this.goToPage("/ExternalLogin")

      }
      else if(this.notaTambahan == 'refresh page')
      {
        this.dialogGeneralPopup = false;
        location.reload();
      }
      else
      {
        this.dialogGeneralPopup = false;
      }
    },

  },
  created () {
    bus.$on('popupMessage', (data) => {

      this.status = data.result;

      if (data.result == "success") {
        this.icon = data.icon;
        this.title = data.title;
        this.text = data.text;
        this.dialogGeneralPopup = true;

        if (data.nota != undefined) {
          this.notaTambahan = data.nota
        }

      }
      else if (data.result == "error") {
        this.icon = data.icon;
        this.title = data.title;
        this.text = data.text;
        this.dialogGeneralPopup = true;

        if (data.nota != undefined) {
          this.notaTambahan = data.nota
        }

      }

    })
  },
  
};
</script>

<style lang="scss" scoped>
@import "~scss/main";
</style>
