import axios from 'axios'
import router from '@/router'
import appMixins from '@/mixins/globalMixins'


export default {
    state: {
        user: '',
        auth: {
            token: 'null',
        }, 
        loading: false,
        error: null,
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setError(state, payload) {
            state.error = payload
        },
        clearError(state) {
            state.error = null
        },
    },
    actions: {
        setUser({ commit }, payload) {
            commit('setUser', payload)
        },

        signUserIn({ commit, getters }, payload) {

            commit('setLoading', true)

            // console.log(payload);

            if(payload.mode == "PLUS")
            {
                // console.log("HELOoooooooooo");
                commit('setUser', payload.objtoken)
                // localStorage.setItem('PlusUser', payload.token)

                // console.log(appMixins.data().vueUrl + 'callapi/cekifadmin.php');

                axios.get(appMixins.data().vueUrl + 'callapi/cekifadmin.php', {  
                headers: {
                        Authorization: 'Bearer ' + payload.objtoken.id_token,
                },
                })
                .then((response) => {

                    // console.log("masyukkkkkkkkkkkkkk");

                    let newObj = {
                        id_token: payload.objtoken.id_token,
                        role_detail: response.data
                    }

                    // console.log(response.data);
                    commit('setUser', newObj);
                    // console.log(getters);
                    localStorage.setItem('PlusUser', JSON.stringify(newObj))
                    localStorage.setItem('Mode', 'PlusUser')


                    setTimeout(() => {  
                        
                        router.push('/LandingPage')

                    }, 3000)
                    
                })
                .catch((error) => {
                    console.log(error.response);

                    setTimeout(() => {  
                        
                        router.push('/')

                    }, 3000)
                    
                })
            }
            else if (payload.mode == "EXTERNAL")
            {
                axios.post(appMixins.data().vueUrl + 'callapi/loginteman.php', payload)
                .then((response) => {
                    // console.log('NI RESPONSE', response.data);
                    // console.log('NI PAYLOAD', payload);
                    // console.log('NI LOCALSTORAGE', localStorage);
                    commit('setLoading', false)
                    commit('setUser', response.data)
                    // console.log(getters.user.id_token);
                    localStorage.setItem('PlusUser', JSON.stringify(response.data))
                    localStorage.setItem('RememberMe', JSON.stringify(payload))
                    localStorage.setItem('Mode', 'ExternalUser')
                    router.push('/Loading')
                })
                .catch((error) => {
                    commit('setLoading', false)
                    commit('setError', error.response.data)
                    alert('wrong username/password')
                    if (error.response.status === 401) {
                        this.signOut();
                    }
                })

                setTimeout(() => { 

                    axios.get(appMixins.data().vueUrl + 'callapi/cekifadmin.php', {
                        headers: {
                            Authorization: 'Bearer ' + getters.user.id_token,
                        },
                    })
                        .then((response) => {

                            // console.log("masyukkkkkkkkkkkkkk");

                            // console.log('NI LOCALSTORAGE2', localStorage);


                            let newObj = {
                                id_token: getters.user.id_token,
                                role_detail: response.data
                            }

                            // console.log(response.data);
                            commit('setUser', newObj);
                            localStorage.setItem('AccessModule', JSON.stringify(newObj.role_detail))


                            // console.log(getters);


                        })
                        .catch((error) => {
                            console.log(error.response);


                        })

                }, 3000)

                

            }

            // console.log(payload);

            
        },

        signOut({ commit }) {

            if (localStorage.RememberMe != undefined)
            {
                let tempPayload = JSON.parse(localStorage.RememberMe)

                // console.log(tempPayload);

                if (tempPayload.checkboxRememberMe == true)
                {
                    localStorage.removeItem("PlusUser")
                }
                else
                {
                    // localStorage.clear()
                }
            }
            else
            {
                // localStorage.clear()
            }
            // localStorage.clear()
            // localStorage.removeItem("PlusUser")
            commit('setUser', "")
            commit('setError', null)
            router.push('/Logout');

        },

        checkUserLocalStorage({ commit, getters }) {
            const user = JSON.parse(localStorage.getItem('PlusUser'))
            if (user) {
                commit('setUser', user)
            }
        },

        clearError({ commit }) {
            commit('clearError')
        },

        refreshToken({ state, commit }) {
            //alert('token '+state.user.token)
            return new Promise((resolve, reject) => {

                // console.log(JSON.parse(localStorage.PlusUser).id_token);
                setTimeout(() => {        

                    
                    // console.log(state);

                    if (!state.user || !state.user.id_token){ 
                        console.log('no token found'); 
                        // return false; 
                        router.push('/').catch(() => { });
                    }
                    else {

                        // console.log(localStorage);

                        // console.log("In Mode");

                        axios.get(appMixins.data().vueUrl + 'callapi/refreshjwt.php', {  //renewjwt xperlu body, only header
                            headers: {
                                Authorization: 'Bearer ' + state.user.id_token,
                            },
                        })
                        .then((response) => {

                            if (localStorage.Mode == 'PlusUser')
                            {
                                // update token on user state
                                // update token on localstorage
                                console.log('token renew at ' + new Date());
                                state.user.id_token = response.data.newjwt;  //get new jwt n put it in state

                                let objOriginal = {
                                    id_token: response.data.newjwt,
                                    role_detail: JSON.parse(localStorage.PlusUser).role_detail
                                }
                                localStorage.setItem('PlusUser', JSON.stringify(objOriginal))

                            }

                            else if (localStorage.Mode == 'ExternalUser')
                            {
                                console.log('token renew at ' + new Date());
                                state.user.id_token = response.data.newjwt;  //get new jwt n put it in state

                                let objOriginal = {
                                    id_token: response.data.newjwt,
                                    role_detail: JSON.parse(localStorage.AccessModule)
                                }
                                // localStorage.setItem('PlusUser', JSON.stringify(objOriginal))
                                commit('setUser', objOriginal);

                            }
                            
                            // window._mycurrentjwt = state.user.token;  //_mycurrentjwt just for debug
                            resolve(response)

                            // console.log("Refreshhhhhhhhhhh",state.user.id_token);
                        })
                        .catch((error) => {
                            console.log(error.response);
                            // set user on state to null
                            // clear local storage
                            // goto login page
                            router.push('/').catch(()=>{})  //kene ke LOGIN
                            // reject(error)
                        })

                    }

                }, 1000)
            })
        },

        updateSessionVue({ commit, getters }){

            axios.get(appMixins.data().vueUrl + 'callapi/cekifadmin.php', {
                headers: {
                    Authorization: 'Bearer ' + getters.user.id_token,
                },
            })
            .then((response) => {

                console.log("renew session access");


                let newObj = {
                    id_token: getters.user.id_token,
                    role_detail: response.data
                }

                console.log(response.data);
                commit('setUser', newObj);
                localStorage.setItem('AccessModule', JSON.stringify(newObj.role_detail))


                console.log(getters);


            })
            .catch((error) => {
                console.log(error.response);


            })

        }

    },
    getters: {
        user(state) {
            return state.user
        },
        token(state) {
            return state.user.token
        },
        error(state) {
            return state.error
        },
        loading(state) {
            return state.loading
        },
        userRole(state) {
            return state.user.role
        },

    },
}
