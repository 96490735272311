import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#0954a2',
                secondary:'#0e4b2a',
                tertiary: '#2596be',

                // success: '#107079',
                // error: '#ff3737',
            },
            dark: {},
        },
    },
    icons: {
        iconfont: 'mdi',
    },

});
