import store from '@/store'

export default async (to, from, next) => {
    //store.dispatch('checkUserLocalStorage')  //ni xperlu, sbb dia buat token digantikan dgn yg lama
    //if (localStorage.user) {


    if (localStorage.PlusUser){
        //alert(111)
        store.dispatch('refreshToken')
        next()
    } else {
        // next('/login').catch(()=>{})
        //next('/').catch(()=>{})   //shaz komen. cam error
        //next('/')

        //this.goToPage("/");
        alert('Invalid session!')  //shaz add
        next("/")

        //return false;  //shaz add
    }
}
